/*
 * Handles the toggling of mail conversation history within the text editor
 * The textfield is prefilled with correct markup from django forms
 **/
export function froalaMailHistory(FroalaEditor: any) {
  FroalaEditor.PLUGINS.MailHistoryPlugin = function (editor: any) {
    return {
      _init() {
        const historyContent = '[data-froala-role=history-content]'
        const historyContentElement = editor.$el.find(historyContent)

        // Initially hide by adding the 'hidden' class
        historyContentElement.addClass('hidden').removeClass('visible')

        editor.$el.on('click', '[data-froala-role=history-toggle-btn]', () => {
          // Toggle between 'visible' and 'hidden' classes
          if (historyContentElement.hasClass('hidden')) {
            historyContentElement.removeClass('hidden').addClass('visible')
          } else {
            historyContentElement.removeClass('visible').addClass('hidden')
          }
        })
      },
    }
  }
}
